@import "~normalize.css";
@import url('https://fonts.googleapis.com/css?family=Karla:400,700');
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --white: #fff;
  --grey: #f1f4f8b0;
  --dark-grey: #6b7c93;
  --green: #24b47e;
  --teal: #4F96CE;
  --blue: #845286;
  --dark-blue: #638EB9;
  --spacer: 28px;
}

body {
  font-family: karla, -apple-system, system-ui, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  color: var(--dark-grey);
}

a {
  text-decoration: none;
  color: var(--blue);
}

a:hover,
a:focus {
  color: var(--dark-blue);
}

ul {
  list-style: none;
  padding-left: 0;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

/* Let's get this party started */
::-webkit-scrollbar {
    width: 20px;
}

/* Track */
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(132,82,134);
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
    border: 1px solid rgb(0,0,0);
}
::-webkit-scrollbar-thumb:window-inactive {
	background: rgba(255,0,0,0.4);
}

::-webkit-scrollbar-track-piece {
  background: black;
}
