@import url(https://fonts.googleapis.com/css?family=Karla:400,700);
@import url(https://fonts.googleapis.com/css?family=Lobster);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --white: #fff;
  --grey: #f1f4f8b0;
  --dark-grey: #6b7c93;
  --green: #24b47e;
  --teal: #4F96CE;
  --blue: #845286;
  --dark-blue: #638EB9;
  --spacer: 28px;
}

body {
  font-family: karla, -apple-system, system-ui, "Segoe UI", "Roboto", "Helvetica Neue", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  color: #6b7c93;
  color: var(--dark-grey);
}

a {
  text-decoration: none;
  color: #845286;
  color: var(--blue);
}

a:hover,
a:focus {
  color: #638EB9;
  color: var(--dark-blue);
}

ul {
  list-style: none;
  padding-left: 0;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

/* Let's get this party started */
::-webkit-scrollbar {
    width: 20px;
}

/* Track */
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(132,82,134);
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
    border: 1px solid rgb(0,0,0);
}
::-webkit-scrollbar-thumb:window-inactive {
	background: rgba(255,0,0,0.4);
}

::-webkit-scrollbar-track-piece {
  background: black;
}

.photo {
  width: 2050px;
  height: 320px;
  padding-top: 40px;
}

.skyBack {
  background-color: white;
}

#front {
  position: fixed;
  z-index: 2;
}
#behind {
  position: fixed;
  z-index: 1;
}
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  transition: top 0.6s;
}
.navbar--hidden {
  top: -65px;
}
.drops {
  padding-left: 5%;
}

@-webkit-keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes slideIn {
  0% {
    transform: translateX(-2%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(-2%);
  }
  100% {
    transform: translateX(0);
  }
}

@-webkit-keyframes shrink {
  0% {
    width: 95%;
  }
  100% {
    width: 90%;
  }
}

@keyframes shrink {
  0% {
    width: 95%;
  }
  100% {
    width: 90%;
  }
}

.sky {
  display: inline-block;
  font-size: 30px;
  text-align: center;
}

.pr {
  display: inline-block;
  font-size: 20px;
}

.in {
  display: inline-block;
  font-size: 15px;
}

.foldParent {
  padding-top: 40px;
}

/* GRID */
.header {
  grid-area: header;
  background: white;
  min-height: 100vh;
}
.main {
  grid-area: main;
  text-align: center;
}
.item-c {
  grid-area: footer;
}
.picContainerLeft {
  grid-area: sideleft;
  width: 50%;
  height: 50%;
  justify-self: start;
}
.picContainerRight {
  grid-area: sideright;
  width: 50%;
  height: 50%;
  justify-self: end;
}
.container {
  display: grid;
  grid-template-columns: 33.3% 33.3% 33.3%;
  grid-template-rows: 33.3% 33.3% 33.3%;
  justify-content: center;
  grid-template-areas:
    "header header header"
    "sideleft main sideright"
    "footer footer footer";
}

