.sky {
  display: inline-block;
  font-size: 30px;
  text-align: center;
}

.pr {
  display: inline-block;
  font-size: 20px;
}

.in {
  display: inline-block;
  font-size: 15px;
}

.foldParent {
  padding-top: 40px;
}
