#front {
  position: fixed;
  z-index: 2;
}
#behind {
  position: fixed;
  z-index: 1;
}
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  transition: top 0.6s;
}
.navbar--hidden {
  top: -65px;
}
.drops {
  padding-left: 5%;
}
